/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

/* Layout */
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Code Highlight */
@import "../node_modules/prismjs/themes/prism-coy.css";

/* Quill Text Editor for p-editor */
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

/* Demos */
@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";

.p-inputgroup .p-inputtext, .p-fluid .p-inputgroup .p-inputtext { 
    width: 100% !important;
    border-radius: 6px 0 0 6px;
}

.agendado {
    background: #3B82F6;
    color: #00095c;
}

.alerta {
    color: red;
}

.admihair-text-white {
    color: #ffffff;
}

.form-control {
    display: block;
    width: 100%;
    height: 3.1rem;
    padding: 0.75rem 0.75rem;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    line-height: 1.42857143;
    color: #1e293b;
    background-color: #fff;
    background-image: none;
    border: 1px solid #cbd5e1;
    border-radius: 6px;
    //box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }

  .form-control:hover {
    border-color: #6366F1;
  }

  .form-control-agrupado {
    display: block;
    width: 100%;
    height: 3.2rem;
    padding: 0.75rem 0.75rem;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    line-height: 1.42857143;
    color: #1e293b;
    background-color: #fff;
    background-image: none;
    border: 1px solid #cbd5e1;
    border-radius: 6px 0px 0px 6px;
    //box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }

  .form-control-agrupado:hover {
    border-color: #6366F1;
  }

  @media (max-width: 768px) {
    .ocultar-div-mobile {
      display: none !important;
    }
}

.formatacao-filtro-table {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
}